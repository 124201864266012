<template>
  <div
    class="tooltip-old bs-tooltip-old-auto tooltip-completion show"
    role="tooltip"
    x-placement="top"
    :style="stylePosition"
    :data-zone="zone"
  >
    <div class="arrow tooltip-completion--arrow"></div>
    <div class="tooltip-old-inner">
      <span class="value">
        <slot name="valueStr">...</slot>
      </span>
      <span class="concept">
        <slot name="conceptStr">...</slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressTooltip",
  data() {
    return {};
  },
  computed: {
    stylePosition() {
      return `left: calc(${this.position}% - 38px)`;
    },
  },
  props: {
    position: {
      type: Number,
      required: false,
    },
    zone: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
$low: #da2840;
// $medium: #f7fe34;
$medium: #fac928;
$high: #60d1b1;

.tooltip-old {
  z-index: 3 !important;
}

.tooltip-completion {
  position: absolute;
  will-change: transform;
  width: 76px;
  max-width: 76px;
  top: 0px;
  left: 0px;
  transform: translateY(-58px);

  &[data-zone="low"] {
    .tooltip-completion--arrow {
      &::before {
        border-top-color: $low !important;
      }
    }
  }
  &[data-zone="medium"] {
    .tooltip-completion--arrow {
      &::before {
        border-top-color: $medium !important;
      }
    }
  }
  &[data-zone="high"] {
    .tooltip-completion--arrow {
      &::before {
        border-top-color: $high !important;
      }
    }
  }

  &--arrow {
    left: calc(50% - 5.5px);
    &::before {
      border-top-color: #aaa !important;
    }
  }

  &[data-zone="low"] {
    .tooltip-old-inner {
      background: $low;
    }
  }

  &[data-zone="medium"] {
    .tooltip-old-inner {
      background: $medium;
    }
  }

  &[data-zone="high"] {
    .tooltip-old-inner {
      background: $high;
    }
  }

  .tooltip-old-inner {
    color: #000;
    background: #aaa;
  }

  .value {
    font-size: 14px;
  }

  .concept {
    font-size: 10px;
  }
}
</style>
