<template>
  <div class="wrapper" v-if="value && progressBarValues">
    <div class="ranking-container">
      <ProgressTooltip :position="tooltipPosition" :zone="zone">
        <div slot="valueStr">{{ value }}</div>
        <div slot="conceptStr">{{ concept }}</div>
      </ProgressTooltip>
      <CProgress :max="progressBarValues.max" animated>
        <CProgressBar :value="progressBarValues.first" color="low" />
        <CProgressBar :value="progressBarValues.second" color="medium" />
        <CProgressBar :value="progressBarValues.third" color="high" />
      </CProgress>
      <div class="legend-container">
        <span class="legend" :style="styleMin">{{
          progressBarValues.legends.min
        }}</span>
        <span class="legend" :style="styleMax">{{
          progressBarValues.legends.max
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressTooltip from "@/components/atoms/ProgressTooltip";
export default {
  name: "RankingProgress",
  components: {
    ProgressTooltip,
  },
  computed: {
    styleMin() {
      return `left: calc(${this.progressBarValues.legends.positionMin}% - ${
        String(this.progressBarValues.legends.min).length
      }ch)`;
    },
    styleMax() {
      return `left: calc(${this.progressBarValues.legends.positionMax}%)`;
    },
    tooltipPosition() {
      let position;
      if (this.code !== "BV" && this.code !== "ACC") {
        position = this.rawValue / (this.progressBarValues.legends.total / 100);
      } else {
        const total = this.progressBarValues.legends.total,
          p2 = this.progressBarValues.legends.max;

        const posRef =
          this.rawValue / (this.progressBarValues.legends.total / 100);

        switch (this.zone) {
          case "low":
            position = posRef - posRef * 0.1;
            break;
          case "medium":
            position =
              (this.rawValue * 100) / total - (this.rawValue - p2) * 0.1;
            break;
          case "high":
            position = posRef + posRef * 0.1;
            break;
        }
      }
      if (position > 100) position = 100;
      if (position < 0) position = 0;
      return position;
    },
    zone() {
      let zone;

      if (this.rawValue < this.criterion.min) zone = "low";
      if (
        this.rawValue >= this.criterion.min &&
        this.rawValue < this.criterion.max
      )
        zone = "medium";
      if (this.rawValue >= this.criterion.max) zone = "high";

      return zone;
    },
  },
  props: {
    code: {
      type: String,
      requred: true,
    },
    value: {
      type: String,
      required: false,
    },
    rawValue: {
      type: Number,
      required: false,
    },
    concept: {
      type: String,
      required: false,
    },
    progressBarValues: {
      type: Object,
      required: false,
    },
    criterion: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
$low: #da2840;
// $medium: #f7fe34;
$medium: #fac928;
$high: #60d1b1;

.bg-low {
  background: $low;
}

.bg-medium {
  background: $medium;
}

.bg-high {
  background: $high;
}

.wrapper {
  padding-top: 60px;
  padding-bottom: 14px;
}
.ranking-container {
  position: relative;
}
.legend-container {
  position: relative;
  height: 14px;
}
.legend {
  position: absolute;
  font-size: 10px;
}
</style>
